
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}









* {
  box-sizing: border-box;
}
body, html {
  margin: 0;
  background: #f2f2f2;
  height: 100vh;
  width: 100%;
}
body, * {
  font-family: 'Aventir', Arial, sans-serif;
}

hr {
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
}

#root {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}











/* Aventir */
@font-face {
  font-family: 'Aventir black';
  font-style: normal;
  font-weight: 900;
  src: local('Aventirltstd-black'),
  url(https://s3.eu-central-1.amazonaws.com/purple-trading-brand-assets/fonts/avenirltstd-black.woff2) format('woff2'),
  url(https://s3.eu-central-1.amazonaws.com/purple-trading-brand-assets/fonts/avenirltstd-black.woff) format('woff');
}
@font-face {
  font-family: 'Aventir heavy';
  font-style: normal;
  font-weight: 700;
  src: local('Aventirltstd-heavy'),
  url(https://s3.eu-central-1.amazonaws.com/purple-trading-brand-assets/fonts/avenirltstd-heavy.woff2) format('woff2'),
  url(https://s3.eu-central-1.amazonaws.com/purple-trading-brand-assets/fonts/avenirltstd-heavy.woff) format('woff');
}
@font-face {
  font-family: 'Aventir medium';
  font-style: normal;
  font-weight: 600;
  src: local('Aventirltstd-medium'),
  url(https://s3.eu-central-1.amazonaws.com/purple-trading-brand-assets/fonts/avenirltstd-medium.woff2) format('woff2'),
  url(https://s3.eu-central-1.amazonaws.com/purple-trading-brand-assets/fonts/avenirltstd-medium.woff) format('woff');
}
@font-face {
  font-family: 'Aventir';
  font-style: normal;
  font-weight: normal;
  src: local('Aventirltstd-roman'),
  url(https://s3.eu-central-1.amazonaws.com/purple-trading-brand-assets/fonts/avenirltstd-roman.woff2) format('woff2'),
  url(https://s3.eu-central-1.amazonaws.com/purple-trading-brand-assets/fonts/avenirltstd-roman.woff) format('woff');
}








@keyframes skeleton {
  0% {
      width: 0%;
      left: 0;
      right: auto;
      opacity: 0.3;
  }
  20% {
      width: 100%;
      left: 0;
      right: auto;
      opacity: 1;
  }
  28% {
      width: 100%;
      left: auto;
      right: 0;
  }
  51% {
      width: 0%;
      left: auto;
      right: 0;
  }
  58% {
      width: 0%;
      left: auto;
      right: 0;
  }
  82% {
      width: 100%;
      left: auto;
      right: 0;
  }
  83% {
      width: 100%;
      left: 0;
      right: auto;
  }
  96% {
      width: 0%;
      left: 0;
      right: auto;
  }
  100% {
      width: 0%;
      left: 0;
      right: auto;
      opacity: 0.3;
  }
}


